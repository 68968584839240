export default {
  data() {
    return {
      iscn: false,
      isen: false,
      tempheight: document.documentElement.clientHeight + 'px'
    };
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    if (this.$store.state.lang == 'en') {
      this.iscn = false;
      this.isen = true;
    } else {
      this.iscn = true;
      this.isen = false;
    }
  }
};