import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../public/loadingCNWin.gif';
import _imports_1 from '../../../public/loadingENWin.gif';
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "100%",
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center",
    "justify-content": "center"
  }
};
const _hoisted_2 = {
  src: _imports_0,
  style: {
    "width": "35%"
  }
};
const _hoisted_3 = {
  src: _imports_1,
  style: {
    "width": "35%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      width: '100%',
      height: $data.tempheight
    })
  }, [_createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_vShow, $data.iscn]]), _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [[_vShow, $data.isen]])])], 4);
}